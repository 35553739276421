import { EntityType } from "~/src/generated-sources/core";

function formatCustomerType(customerType: EntityType) {
  switch (customerType) {
    case "private":
      return "Privatkunde";
    case "company":
      return "Firma";
    case "ngo":
      return "Verein/Organisation";
  }
}

export default () => {
  const customerTypes = [];
  for (const value of Object.values(EntityType)) {
    customerTypes.push({ label: formatCustomerType(value), value });
  }
  return customerTypes;
};
